
.newsdetailcontents {
  max-width: 1365px;
  margin: 0 auto;


  &-news {
    max-width: 73.2%;
    margin: 0 auto;

    h1 {
      font-size: 26px;
      letter-spacing: 3px;
      font-weight: bold;
      padding-top: 81px;
      margin-bottom: 22px;
      line-height: 26px;
      margin-top: 0;

    }

    &-share {
      display: flex;
      justify-content: space-between;
      align-items: center;

    }

    &-date {
      font-size: 14px;
      letter-spacing: 2px;
      color: #999;
      font-weight: bold;
    }

    &-sharebuttonwrap {
      display: flex;
      width: 30%;
      align-items: center;
      justify-content: flex-end;
    }

    &-arrow {
      font-weight: bold;
      font-size: 12px;
      letter-spacing: 2px;
      position: relative;
      // margin-left: 60%;
      margin-right: 17.5%;
      font-weight: bold;

      &:after {
        content: '';
        display:inline-block;
        width: 6px;
        height: 6px;
        border: 1px solid;
        border-color:  transparent transparent #000 #000;
        position: absolute;
        top: 2px;;
        right: -29%;
        transform: translateY(-50%);
        transform: rotate(225deg);
      }
    }

    &-twitter {
      margin-right: 11%;

      width: 25px;
      height: 22px;


      img {
        width: 25px;
        display: block;
      }
    }

    &-facebook {
      width: 14px;
      height: 26px;

      img {
        width: 14px;
        display: block;
      }
    }

    &-defaultimagewrapper {
      margin-bottom: 44px;
      margin-top: 11px;
    }

    &-defaultimage {
      width: 40.9%;

      display: inline-block;
    }

    &-mainimage {
      width: 100%;
      margin-bottom: 44px;
      margin-top: 11px;
    }

    &-text {
      span {
         line-height: calc( 1em + 24px);
      }
      margin-bottom: 100px;


      p {
        font-size: 16px;
        line-height: 40px;
        letter-spacing: 2px;
      }

      ol,li {
        font-size: 16px;
        line-height: 40px;
        letter-spacing: 2.8px;
        padding-left: 0;
      }
      ul,ol {
        padding-left: 40px;
      }
    }



    &-buttons {
      // display: flex;
      // justify-content: space-between;
      padding-bottom: 130px;
      position: relative;
      max-width: 100%;
      // margin:0 auto;
    }

    &-buttonleft {
      // margin-right: 24.5%;
      position: absolute;
      // display: block;
      // float: left;
      top: 0;
      left: 0;
    }

    &-buttoncenter {
      // margin-right: 24.5%;
      position: absolute;
      top: 0;
      left: 41%;
      // margin:0 auto
      display: block;
      // left: 24.3%;
    }

    &-buttonright {
      position: absolute;
      // left: 800px;
      // display: block;
      // float: right;
      right: 0;
      top: 0;
    }

  }

  &-button {
    color: #000;
    background-color: #fff;
    text-align: center;
    display: inline-block;
    font-size: 12px;
    letter-spacing: 2px;
    font-weight: bold;
    border: 1.5px solid #000;

    width: 166px;
    padding-top: 17px;
    padding-bottom: 17px;
    position: relative;
    transition:all .3s;

    &:after {
      content: '';
      display:inline-block;
      width: 6px;
      height: 6px;
      border: 1px solid;
      border-color:  transparent transparent #000 #000;
      position: absolute;
      top: 41%;
      right: 7%;
      transform: translateY(-50%);
      transform: rotate(225deg);
    }
  }


  &-arrowleftbutton {
    color: #000;
    background-color: #fff;
    text-align: center;
    display: inline-block;
    font-size: 12px;
    letter-spacing: 2px;
    font-weight: bold;
    border: 1.5px solid #000;

    width: 166px;
    padding-top: 17px;
    padding-bottom: 17px;
    position: relative;
    transition:all .3s;

    &:after {
      content: '';
      display:inline-block;
      width: 6px;
      height: 6px;
      border: 1px solid;
      border-color:  transparent transparent #000 #000;
      position: absolute;
      top: 41%;
      right: 85%;
      transform: translateY(-50%);
      transform: rotate(45deg);
    }

  }
}

@media only screen and (max-width: 960px){
  .newsdetailcontents {
    iframe {
      width: 100%;
    }
    &-news {
      width: 670 / 20 + rem;
      max-width:none;
      margin: 0 auto;
      h1 {
        font-size: 39 / 20 + rem;
        line-height: 64 / 20 + rem;
        letter-spacing: 3 / 20 + rem;
        font-weight: bold;
        padding-top: 4rem;
        margin-bottom: 10 / 20 + rem;
      }
      &-share {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      &-date {
        font-size: 1rem;
        letter-spacing: .1rem;
      }

      &-sharebuttonwrap {
        display: flex;
        width: 70rem;
        align-items: center;
        justify-content: flex-end;
      }

      &-arrow {
        font-weight: bold;
        font-size: .9rem;
        letter-spacing: .1rem;
        position: relative;
        margin-right: 73 / 20 + rem;
        font-weight: bold;
        &:after {
          content: '';
          display:inline-block;
          width: .4rem;
          height: .4rem;
          border: .1rem solid;
          border-color:  transparent transparent #000 #000;
          position: absolute;
          top: .1rem;
          right: -25 / 20 + rem;
          transform: translateY(-50%);
          transform: rotate(225deg);
        }
      }

      &-twitter {
        margin-right: 45 / 20 + rem;
        width: 2rem;
        height: auto;
        img {
          width: 2rem;
          display: block;
        }
      }

      &-facebook {
        width: 1rem;
        height: auto;

        img {
          width: 1rem;
          display: block;
        }
      }

      &-defaultimagewrapper {
        margin-bottom: 2.2rem;
        margin-top: .5rem;
      }

      &-defaultimage {
        width: 40.9%;
        display: inline-block;
      }

      &-mainimage {
        width: 100%;
        margin-bottom: 2.2rem;
        margin-top: 30/ 20 + rem;
      }

      &-text {
        span {
           line-height: calc( 1em + 1.2rem);
        }
        margin-bottom: 90 / 20 + rem;
        p ,li,ol{
          font-size: 1.2rem;
          line-height: 63 / 20 + rem;
          letter-spacing: .1rem;

        }
        li{
          padding-left: 0rem;
        }
        ul,ol{
          padding-left: 2rem;
        }
      }
      &-buttons {
        padding-bottom: 10rem;
        margin-left:-1rem ;
        margin-right:-1rem ;
        max-width: none;
      }
      &-buttoncenter {
        left: 250 / 20 + rem;
      }

      &-buttonright {
        position: absolute;

        right: 0;
        top: 0;
      }

    }

    &-button,&-arrowleftbutton,&-arrowleftbutton {
      color: #000;
      background-color: #fff;
      text-align: center;
      display: inline-block;
      font-size: .9rem;
      letter-spacing: .1rem;
      font-weight: bold;
      border: 0.1rem solid #000;
      width: 210 / 20 + rem;
      padding: 1rem;

      &:after {
        content: '';
        display:inline-block;
        width: .4rem;
        height: .4rem;
        border: .1rem solid;
        border-color:  transparent transparent #000 #000;
        position: absolute;
        top: 41%;
        right: 7%;
        transform: translateY(-50%);
        transform: rotate(225deg);
      }
    }


    &-arrowleftbutton {

      &:after {
        right: auto;
        left: 7%;
        transform: rotate(45deg);
      }
    }
  }
}
