
/**
 * Footer
 * ==========================================================================
 */

.footer-wrap {
  background-color: #000;
  padding-bottom: 20px;
}


.footer-inner {
  margin: 0 auto;
  max-width: 1365px;
  display: flex;
  justify-content: space-between;
  &-logo1 {
    width: 30%;
    margin-left: 2.5%;
    margin-top: 36px;

    img {
      width: 115px;
      display: block;
    }
  }

  &-logo2 {
    width: 30%;
    // margin-left: 36.7%;
    margin-top: 20px;
    text-align: center;

    img {
      width: 66px;
      display: inline-block;

    }
  }


  &-buttons {
    width: 30%;
    display: flex;
    margin-right: 2.5%;
    justify-content: flex-end;
  }


  &-contact {
    margin-top: 36px;
    margin-left: 30.4%;
    font-weight: bold;
    font-size: 12px;
    letter-spacing: 2px;

    a {
      color: #fff;
      // border-bottom: 2px solid #fff;
      padding-bottom: 4px;
      text-transform: uppercase;
      position: relative;
      overflow:hidden;
      transition: .3s;
      display:block;
      &:after {
        position: absolute;
        transition: .3s;
        content: '';
        width: 0;
        left: 50%;
        bottom: 0;
        height: 2px;
        background: #fff;
        display: inline-block;
      }
    }
  }


  &-twitter {
    margin-left: 9.7%;
    margin-top: 33px;
    width: 25px;
    a {
      display: block;
    }

    img {
      width: 25px;
      display: block;
    }
  }

  &-facebook {
    margin-left: 9.5%;
    margin-top: 30px;
    width: 14px;
    // margin-right: 3%;

    a {
      display: block;
    }

    img {
      display: block;
      width: 14px;
    }
  }

}

@media only screen and (max-width: 960px){

  .footer-wrap {
    padding-bottom: 3rem;
  }
  .footer-inner {
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    &-logo1 {
      width: 112 / 20 + rem;
      margin-left: 2rem;
      margin-top: 34 / 20 + rem;
      img {
        width: 100%;
        display: block;
      }
    }

    &-logo2 {
      display: none;
    }
    &-buttons {
      display: flex;
      margin-right: 2rem;
      justify-content: flex-end;
    }

    &-contact {
      margin-top: 34 / 20 + rem;
      margin-right: 1.5rem;
      font-size: .9rem;
      letter-spacing: .1rem;

      a {
        color: #fff;
        // border-bottom: 2px solid #fff;
        padding-bottom: 4px;

        text-transform: uppercase;
        position: relative;
        overflow:hidden;
        transition: .3s;

        &:after {
          position: absolute;
          transition: .3s;
          content: '';
          width: 0;
          left: 50%;
          bottom: 0;
          height: 2px;
          background: #fff;
        }
      }
    }


    &-twitter {
      margin-top: 1.2rem;
      width: 2rem;
      a {
        display: block;
      }

      img {
        width: 2rem;
        display: block;
        max-width: none;
      }
    }

    &-facebook {
      margin-left: 9.5%;
      margin-top: 30px;
      width: 14px;
      a {
        display: block;
      }

      img {
        display: block;
        width: 14px;
      }
    }

  }

}
