
/**
 * Header
 * ==========================================================================
 */


.header-inner {
  margin: 0 auto;
  max-width: 1365px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &-logo {
    margin-left: 2.9%;
    width: 107 / 1365 * 100%;
    a {
      display: block;
    }
    svg {
      transition: all .3s;
    }
  }

  &-menu {
    margin-top: 60/ 1365 * 100%;
    margin-bottom: 60/ 1365 * 100%;
    width: 70%;
    margin-right: 3%;
  }

  &-link {

    font-weight: bold;
    font-size: 12px;
    letter-spacing: 2px;
    // border-bottom: 2px solid #000;
    padding-bottom: 7px;
    color: #000;

    text-transform: uppercase;
    position: relative;
    overflow:hidden;
    transition: .3s;

    &:after {
      position: absolute;
      transition: .3s;
      content: '';
      width: 0;
      left: 50%;
      bottom: 0;
      height: 2px;
      background: #000;
    }
  }


  ul {
    text-align: right;
    padding: 0;
  }

  li {
    display: inline-block;
    margin-left: 6%;
    position: relative;
  }
}

@media only screen and (max-width: 960px){
  .header-inner {
    max-width: none;
    padding: 30 / 20 + rem 40 / 20 + rem;
    width: 100%;

  }
  .header-inner-logo{
    margin: 0;
    width: 128 / 20 + rem;

    svg{
      width: 128 / 20 + rem;
      height: 4rem;
    }
  }
  .header-inner-menu {
    position:fixed;
    top:0;
    right: 0;
    width: 100%;
    height: 100vh;
    background-color:#000;
    z-index: 10000;
    margin: 0;
    display: none;
    overflow-y: scroll;
    &.active {
      right: 0px;
    }
    ul {
      width: 240 / 20 + rem;
      margin: 345 / 20 + rem auto ;
      @media (orientation: landscape){
        margin: 150 / 20 + rem auto ;
      }
      li {
        display: block;
        margin-left:0;
        &:not(:last-child){
          margin-bottom: 65 / 20 + rem!important;
        }
        a {
          display: block;
          color: #ffffff;
          font-size: 36 / 20 + rem;
          text-align: center;
          margin-bottom: 0;
          text-align: left;
        }
      }
    }
  }
  .header-inner-menuBtn {
    width: 24rem;
    a {
      display: flex;
      color: #000;
      font-size: .9rem;
      font-weight: bold;
      justify-content: flex-end;
      align-items: center;
      letter-spacing: 3px;
      height: 3rem;
    }
    .menuBtn {
      width: 52 / 20 + rem;
      height: 27 / 20 + rem;
      position: relative;
      display: block;
      transition: all .4s;
      box-sizing: border-box;
      cursor: pointer;
      z-index: 1000;
      margin-left: 0.75rem;
      overflow: visible;
      span {
        position: absolute;
        width: 100%;
        height: 1px;
        background-color:#000;
        display: block;
        transition: all .5s;
        box-sizing: border-box;

        &:first-child {
          top: 0;
        }
        &:nth-child(2) {
          top: 12 / 20 + rem;
          transition: all 0.5s;

        }
        &:last-child {
          bottom: 0.1rem;
        }
      }
    }
  }
  .header-inner-menuTop {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    display:flex;
    justify-content: space-between;
    padding: 35 / 20 + rem 2rem;
    a span {

      &:first-child {
        top: 30 / 20 + rem!important;
        left: -6 / 20 + rem;
        transform: translateY(0.4rem) rotate(-315deg);
        @media (orientation: landscape){
          // transform: translateY(0.2rem) rotate(-315deg);
        }
      }
      &:nth-child(2) {
        opacity: 0;
        transition: all 0s;
      }
      &:last-child {
        top: 3.2rem!important;
        left: -6 / 20 + rem;
        bottom: auto;
        transform: translateY(-1.25rem) rotate(315deg);
        // @media (orientation: landscape){
        //   // transform: translateY(-0.63rem) rotate(315deg);
        // }
      }
    }
    .header-inner-logo svg g{
      fill: #fff;
    }
    a {
      color: #fff;
    }
    .header-inner-menuBtn {

      p {
        display: none;
      }
    }
    .menuBtn {
      height: 38 / 20 + rem;
      width: 38 / 20 + rem;
      height: 3rem;

      a {
        color: #fff;

      }
      span {
        background-color: #fff;
        width: 140%;
      }
    }

    .header-inner {
      align-items: end;
    }
  }
  .menu-inner-twitter {
    width: 2rem;
    margin: 0 auto 2rem;
     bottom: 90 / 20 + rem;
  }
}
