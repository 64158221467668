
.lihide {
  display: none;
}

.workscontents {
  // max-width: 1365px;
  ol, ul {
    list-style: none;
    padding: 0;
  }
  margin: 0 auto;


  &-works {
    text-align:  center;

    &-bold {
      font-weight: bold;
    }
    &-title {
      margin-bottom: 45px;
      padding-top: 80px;

      &-1 {
        margin-left: 0.5%;
      }

      &-2 {
        margin-top: 49px;
        margin-left: -1%;
      }

      span {
        font-size: 66px;
        letter-spacing:10px;
        font-weight: bold;
        display: inline-block;
        vertical-align: top;
      }
    }

    &-categorywrap {
      // max-width: 1365px;
      max-width: 1120px;
      margin: 0 auto;
    }

    &-categories {
      margin-bottom: 10px;
      justify-content: center;
      align-items: center;
      li {
        display: inline-block;
        font-size: 12px;
        letter-spacing: 1px;
        padding-left: 13px;
        margin-right: 10px;
        position: relative;
        line-height: 36px;
      }

      li:not(:last-child):after {
        content: "";
        border-right: 1px solid #000;
        position: absolute;
        right: -10px;
        height: 12px;
        top: 13px;
        bottom: 0;
        line-height: 15px;
      }
      a {
        border-bottom: 1px solid #000;
        color: #000;
      }
    }
    &-wrap {
      max-width: 100%;
      padding-bottom: 60px;
      margin-top: 87px;
    }


    &-wrapul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: 0;
      align-items: stretch;
      &-li {
        width: 49%;
        background-color: #fff;
        margin-bottom: 50px;
      }

      a {
        display: block;
            height: 100%;
            background-image: linear-gradient(to right, #fff 50%, #000 50%);
            background-position: 0 0;
            background-size: 200% auto;
            transition: all .3s ease 0s;
            //padding:1% 2% 2% 2%;
            //height: 100%;
      }

      &-li:nth-child(odd) {
        // margin-top: 60px;
        transform: translateY(60px);
          span {
          text-align: left;
          display: inline-block;
          .workscontents-works-content-under-movietitle{
            display: inline-block;
            text-align: left;
          }

        .workscontents-works-content-under-rolewrap {
          justify-content: flex-end;
        }

        }
      }

      &-li:nth-child(even) {
        // margin-top: -10px;
        transform: translateY(-10px);

        .workscontents-works-content-under {
          text-align: left;
        }
        .workscontents-works-content-under-movietitle{
              text-align: left;
        }
        .workscontents-works-content-under-rolewrap{
          justify-content: flex-start!important;

        }
      }
    }

    &-content {
      &-under {

        &-movietitle {
          color: #000;
          font-size: 16px;
          letter-spacing: 2px;
          font-weight: bold;
          display: block;
          line-height: 26px;
          text-align: right;
          padding: 1% 1.2% 1.5%;
        }

        &-rolewrap {
          display: flex;
          flex-wrap:wrap;
          justify-content: flex-end;
          padding: 0% 1.2% 3%!important;
        }


        &-role {
          color: #000;
          font-size: 12px;
          letter-spacing: 2px;
          //border-left:1px solid #000;
          margin-right: 10px;
          padding-right: 10px;
          line-height: 20px;
          position: relative;
          &:not(:last-child):after {
            content: "";
            border-right: 1px solid #000;
            position: absolute;
            right: 0px;
            height: 11px;
            top: 5px;

          }
        }

        &-role:first-child {
           border-left:none;
           padding-left: 0;
        }
        &-role:last-child {
           padding-right: 0;
        }

      }


      &-imagewrapper {
        overflow: hidden;
        background-color: #fff;
      }

      &-image {
        display: block;
        overflow: hidden;
        padding-top: 55.2%;
        transition: 0.5s;
      }
      &-defaultimagewrapper {
        margin: 0 auto;
        overflow: hidden;
      }
      &-defaultimage {
        padding-top: 18.6%;
        padding-bottom: 18.6%;
        width: 28.2%;
        transition: 0.5s;
      }
    }



    &-button {
      width: 166px;
      padding-top: 17px;
      padding-bottom: 30px;
      position: relative;
      margin-bottom: 130px;
    }

  }

  &-button {
    color: #000;
    background-color: #fff;
    text-align: center;
    display: inline-block;
    font-size: 12px;
    letter-spacing: 2px;
    font-weight: bold;
    border: 1.5px solid #000;
    transition:all .3s;

    &:after {
      content: '';
      display:inline-block;
      width: 6px;
      height: 6px;
      border: 1px solid;
      border-color:  transparent transparent #000 #000;
      position: absolute;
      top: 41%;
      right: 7%;
      transform: translateY(-50%);
      transform: rotate(225deg);
    }
  }


  &-morebutton {
    color: #000;
    background-color: #fff;
    text-align: center;
    display: inline-block;
    font-size: 12px;
    letter-spacing: 2px;
    font-weight: bold;
    border: 1.5px solid #000;
    transition:all .3s;
    &:after {
      content: '';
      display:inline-block;
      width: 6px;
      height: 6px;
      border: 1px solid;
      border-color:  transparent transparent #000 #000;
      position: absolute;
      top: 65%;
      right: 47.5%;
      transform: translateY(-50%);
      transform: rotate(-45deg);
    }
  }
}
.noArticle {
  text-align: center;
  width: 100%;
  margin: 0;
  padding-bottom: 3em;
}

@media only screen and (max-width: 960px) {
  .workscontents {
    &-works {
      &-title {
        padding: 90 / 20 + rem 0;
        margin-bottom: 0;
        &-1 {
          margin-right: -1.2rem;
          margin-left:0;
        }
        &-2 {
          margin-top: 3.8rem;
          margin-left: 0;
          margin-right: -2.5rem;
        }
        span {
          font-size: 99 / 20 + rem;
          letter-spacing: 1rem;
        }
      }

      &-categorywrap {
        // max-width: 1365px;
        max-width: none;
        margin: 0 1rem;
      }

      &-categories {
        margin-bottom: 0;
        li {
          font-size: .9rem;
          letter-spacing: .05rem;
          padding-left: .5rem;
          margin-right: .5rem;
          line-height: 2.6rem;
        }
        li:not(:last-child):after {
          content: "";
          border-right: 1px solid #000;
          right: -0.5rem;
          height: 1rem;
          top: 0.9rem;
          bottom: 0;
          line-height: auto;
        }
      }

      &-wrap {
        max-width: 100%;
        padding-bottom: 0/ 20 + rem;
        margin-top: 55 / 20 + rem;
      }


      &-wrapul {
        display: block;
        &-li {
          width: 100%;
          margin-bottom: 4.5rem;
          .workscontents-works-content-under-rolewrap {
            justify-content: flex-start!important;
          }
        }
        &-li:nth-child(odd) {
          margin-top: 0px;
          transform:translateY(0);

          .workscontents-works-content-under {
            text-align: left;
          }

        }

        &-li:nth-child(even) {
          margin-top: 0px;
          transform:translateY(0);

          .workscontents-works-content-under {
            text-align: left;
          }
        }
      }

      &-content {
        &-under {
          text-align: left;
          padding: 0.6rem 1rem 1.2rem;
          &-movietitle {
            font-size: 1.2rem;
            letter-spacing: .1rem;
            margin-bottom: 0.5rem;
            line-height: 39 / 20 + rem;
            text-align: left;
            padding: 0.75rem 1rem 0rem;
          }
          &-rolewrap{
            padding: 0 1rem 1rem!important;

          }
          &-role {
            color: #000;
            font-size: .9rem;
            letter-spacing: .1rem;
            padding-right: 0.7rem;
            margin-right: 0.7rem;
            margin-left: 0;
            padding-left: 0;
            position: relative;
            display: inline-block;
            line-height: 1.2rem;
            &:not(:last-child):after {
              content: "";
              border-right: 1px solid #000;
              position: absolute;
              right: 0;
              height: 0.9rem;
              top: 0.2rem;

            }
          }
        &-image {
          padding-top: 414 / 20 + rem;
        }
        &-defaultimage {
          padding-top: 18.6%;
          padding-bottom: 18.6%;
          width: 28.2%;
          transition: 0.5s;
        }
      }
    }
    .workscontents-morebutton {
      font-size: .9rem;
      letter-spacing: .1rem;
      border: 0.1rem solid #000;
      width: 250 / 20 + rem;
      padding: 27 / 20 + rem 0 45 / 20 + rem;
      margin-bottom: 10rem;
      &:after {
        width: .4rem;
        height: .4rem;
        border: 0.1rem solid;
        border-color:  transparent transparent #000 #000;
        position: absolute;
        top: 65%;
        right: 47.5%;
        transform: translateY(-50%);
        transform: rotate(-45deg);
      }
    }
  }
  }
}
