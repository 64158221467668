.animElemWrap{
  //.animElemWrapした中で順番にアニメーションする
}
.animElem.bounce-in{
  opacity : 0;
  animation: none;
}
.animElem .fadeIn{
  opacity : 0;
}
.animElem.zoomFade{
  transform: scale(0.6);
  //opacity : 0;
}
.animElem.fadeIn{
  opacity : 0;
}

.bounce-in{
  animation: bounce-in 0.4s ease 0s;
}

.fadeIn{
  transition: opacity 1s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition-delay: .5s;
  opacity : 1;
  transform: translateY(0px);
}
.fadeIn.contents-about-text2 {
  transition: opacity 1.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition-delay: 0.2s;
}
.zoomFade{
  transform: scale(1);
  transition: all 3.0s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.slideInLeft,.slideInRight{
  transform: translateY(0px);
  opacity : 1;
  transition: all 1.0s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition-delay: .5s;
}
.animElem .slideInLeft{
  transform: translateY(-30px);
  opacity : 0;
}
.animElem .slideInRight{
  transform: translateY(30px);
  opacity : 0;
}

.contents-news-wrap{
  ul{
    // margin-top: 30px;
    animation: fadein2 3s;
  }
  ul.animElem{
    // margin-top: 30px;
    transform: translateY(100px);
    transition: translateY 2s;
    // opacity: 0;
    // transform: translateY(100px);

  }
}

.fadein {
    opacity : 0;
    transform : translateY(100px);
    transition : all 1000ms;
    transition-delay: .2s;
}
.fadein.scrollin {
    opacity : 1;
    transform: translateY(0px);
}
.contents-news-wrap {
  .fadein.scrollin {
      opacity : 1;
      transform: translateY(0px);
  }
}
@media only screen and (min-width: 961px) {
  .contents-news-wrap {
    .fadein.scrollin {
        opacity : 1;
        &:nth-child(4n+1) {
          transform: translateY(30px);
        }

        &:nth-child(4n+2) {
          transform: translateY(60px);
        }

        &:nth-child(4n+3) {
          transform: translateY(0px);
        }
        &:nth-child(4n+4) {
          transform: translateY(30px);
        }
    }
  }
}
.contents-works-list-content {

}


/////////////////////////kye frames////////////////////////


/* KV logo */
@keyframes fadein {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(-50%);
  }
}
@keyframes fadeinSp {
  from {
      opacity: 0;
      transform: translate(-100%,-50%);
  }
  // to {
  //     opacity: 1;
  //     transform: translate(-50%,50%);
  // }
}

@keyframes fadein2 {
  from {
      opacity: 0;
      transform: translateY(100px);
  }
  to {
      opacity: 1;
      transform: translateY(0%);
  }
}
/* KV arrow */
@keyframes Flash1{
  50%{
    opacity: 0;
  }
}
