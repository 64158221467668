
.lihide {
  display: none;
}


.newscontents {
  max-width: 1365px;
  margin: 0 auto;
  ol, ul {
    list-style: none;
  }

  &-news {
    text-align:  center;

    &-title {
      margin-bottom: 70px;
      padding-top: 80px;

      &-1 {
        margin-left: 2.1%;
      }

      &-2 {
        margin-top: 48px;
        margin-left: -1%;
      }

      span {
        font-size: 66px;
        letter-spacing: 10px;
        font-weight: bold;
        display: inline-block;
        vertical-align: top;
      }
    }

    &-wrap {
      ul {
        padding-left: 0;
        padding-bottom: 78px;
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        li:nth-child(4n+1) {
          transform: translateY(30px);
        }
        li:nth-child(4n+2) {
          transform: translateY(60px);
        }
        li:nth-child(4n+4) {
          transform: translateY(30px);
        }
      }

      li {
        background-color: #fff;
        vertical-align: top;
        position: relative;
        width: 318 / 1365 * 100%;
        margin-bottom: 40px;
        margin-right: 30 / 1365 * 100%;

        &:nth-child(4n) {
          margin-right: 0;
        }
      }

      a {
        display: block;
        height: 96%;
      }
    }

    &-imagewrapper {
      margin: 0 auto;
      overflow: hidden;
      width: 91.5%;
      margin-top: 4.5%;
      margin-bottom: 5.3%;
    }

    &-image {
      display: block;
      overflow: hidden;
      padding-top: 69%;
      transition: 0.5s;
    }

    &-defaultimagewrapper {
      background-color: #f2f2f2;
      margin: 0 auto;
      overflow: hidden;

    }

    &-defaultimage {
      padding-top: 21.4%;
      padding-bottom: 21.4%;
      width: 40.9%;
      transition: 0.5s;
    }

    &-link {
      text-align: left;
      font-size: 16px;
      letter-spacing: 2px;
      width: 91.1%;
      color: #000;
      text-decoration: none;
      font-weight: bold;
      display: block;
      margin: 0 auto 20%;
      // margin-top: -10%;
      line-height: 26px;
    }

    &-date {
      color: #999999;
      font-size: 14px;
      letter-spacing: 2px;
      position: absolute;

      left: 4.4%;
      bottom: 3.7%;
      font-weight: bold;
    }

    &-boxbutton {
      position: absolute;
      bottom: 3.6%;
      right: 4.2%;
      padding-top: 4.5%;
      padding-bottom: 4.5%;
      width: 51%;
    }

    &-button {
      width: 166px;
      padding-top: 17px;
      padding-bottom: 30px;
      position: relative;
      margin-bottom: 160px;
    }
  }

  &-button {
    color: #000;
    background-color: #fff;
    text-align: center;
    display: inline-block;
    font-size: 12px;
    letter-spacing: 2px;
    font-weight: bold;
    border: 1.5px solid #000;
    transition:all .3s;

    &:after {
      content: '';
      display:inline-block;
      width: 6px;
      height: 6px;
      border: 1px solid;
      border-color:  transparent transparent #000 #000;
      position: absolute;
      top: 41%;
      right: 7%;
      transform: translateY(-50%);
      transform: rotate(225deg);
    }
  }


  &-morebutton {
    color: #000;
    background-color: #fff;
    text-align: center;
    display: inline-block;
    font-size: 12px;
    letter-spacing: 2px;
    font-weight: bold;
    border: 1.5px solid #000;
    transition:all .3s;

    &:after {
      content: '';
      display:inline-block;
      width: 6px;
      height: 6px;
      border: 1px solid;
      border-color:  transparent transparent #000 #000;
      position: absolute;
      top: 65%;
      right: 47.5%;
      transform: translateY(-50%);
      transform: rotate(-45deg);
    }
  }
}

@media only screen and (max-width: 960px) {
  .newscontents {
    margin: 0 1rem ;
    &-news {
      &-title {
        padding: 90 / 20 + rem 0;
        margin-bottom: 0;
        &-1 {
          margin-right: -1.2rem;
          margin-left:0;
        }
        &-2 {
          margin-top: 3.8rem;
          margin-left: 0;
          margin-right: -2.5rem;
        }
        span {
          font-size: 99 / 20 + rem;
          letter-spacing: 1rem;
        }
      }

      &-wrap {
        ul {
          padding-bottom: 135 / 20 + rem;
          li {
            background-color: #fff;
            vertical-align: top;
            position: relative;
            width: 49%;
            margin-bottom: 3rem;
            margin-right: 2%;
            &:nth-child(2n) {
              margin-right: 0;
            }
            &:nth-child(even) {
              transform: translateY(2.25rem);
            }
            &:nth-child(odd) {
              transform: translateY(0);
            }
          }
        }
      }

      &-imagewrapper {
        margin: 0.75rem;
      }
      &-image {
        padding-top: 217 / 20 + rem;
      }
      &-defaultimage {
        padding-top: 18.6%;
        padding-bottom: 18.6%;
        width: 49.2%;
        transition: 0.5s;
      }
      &-link {
        text-align: left;
        font-size: 1.2rem;
        letter-spacing: .1rem;
        margin: 0 auto 135 / 20 + rem;
        line-height: 1.9rem;
      }
      &-date {
        color: #999999;
        font-size: 19 / 20 + rem;
        letter-spacing: .1rem;
        left:.75rem;
        bottom: .75rem;
        font-weight: bold;
      }
      &-boxbutton {
        position: absolute;
        right:.75rem;
        bottom: .75rem;
        padding: 1rem;
        width: 190 / 20 + rem;
      }
    }

    &-button {
      color: #000;
      background-color: #fff;
      text-align: center;
      display: inline-block;
      font-size: .9rem;
      letter-spacing: .1rem;
      font-weight: bold;
      border: 0.1rem solid #000;
      transition:all .3s;
      &:after {
        content: '';
        display:inline-block;
        width: .4rem;
        height: .4rem;
        border: 0.1rem solid;
        border-color:  transparent transparent #000 #000;
        position: absolute;
        top: 41%;
        right: 7%;
        transform: translateY(-50%);
        transform: rotate(225deg);
      }
    }
    &-morebutton {
      font-size: .9rem;
      letter-spacing: .1rem;
      border: 0.1rem solid #000;
      width: 250 / 20 + rem;
      padding: 27 / 20 + rem 0 45 / 20 + rem;
      margin-bottom: 10rem;
      &:after {
        width: .4rem;
        height: .4rem;
        border: 0.1rem solid;
        border-color:  transparent transparent #000 #000;
        position: absolute;
        top: 65%;
        right: 47.5%;
        transform: translateY(-50%);
        transform: rotate(-45deg);
      }
    }
  }
}
