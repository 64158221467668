// .page-template-news-page,.page-template-works-page,.home{
//   html, body, div, span, applet, object, iframe,
//   h1, h2, h3, h4, h5, h6, p, blockquote, pre,
//   a, abbr, acronym, address, big, cite, code,
//   del, dfn, em, img, ins, kbd, q, s, samp,
//   small, strike, strong, sub, sup, tt, var,
//   b, u, i, center,
//   dl, dt, dd, ol, ul, li,
//   fieldset, form, label, legend,
//   table, caption, tbody, tfoot, thead, tr, th, td,
//   article, aside, canvas, details, embed,
//   figure, figcaption, footer, header, hgroup,
//   menu, nav, output, ruby, section, summary,
//   time, mark, audio, video {
//     margin: 0;
//     padding: 0;
//     border: 0;
//     font: inherit;
//     font-size: 100%;
//     vertical-align: baseline;
//     -webkit-font-smoothing: antialiased;
//     list-style: none;
//   }
// }
body {
  margin:0;
}
html {
  line-height: 1;
}
ol, ul {
  //list-style: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
caption, th, td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle;
}
q, blockquote {
  quotes: none;
}
q:before, q:after, blockquote:before, blockquote:after {
  content: "";
  content: none;
}
a img {
  border: none;
}
article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block;
}
a {
 text-decoration: none;
}

* {
  box-sizing: border-box;
}

strong {
  font-weight: bold;
}

em {
  font-style: italic;
}

blockquote {
    position: relative;
    padding: 30px 15px 8px 15px;
    box-sizing: border-box;
    font-style: italic;
    background: #e0e0e0;
    // color: #555;
    margin: 0;
}
blockquote:before{
    display: inline-block;
    position: absolute;
    top: 5px;
    left: 3px;
    vertical-align: middle;
    content: "“";
    font-family: sans-serif;
    color: #999999;
    font-size: 90px;
    line-height: 1;
}
blockquote p {
    padding: 0;
    margin: 10px 0;
    line-height: 1.7;
}

blockquote cite {
    display: block;
    text-align: right;
    color: #888888;
    font-size: 0.9em;
}

ol {
  list-style-type: decimal;
}

img {
  max-width: 100%;
  height: auto;
}

.aligncenter {
    display: block;
    margin: 0 auto;
}
.alignright { float: right; }
.alignleft { float: left; }

body {
  font-family: YuGothic, 'Yu Gothic Medium', 'Hiragino Sans', 'Hiragino Kaku Gothic ProN', Meiryo, sans-serif!important;
}

.montserrat {
  font-family: 'Montserrat', sans-serif;
  // font-weight: 400;
}



.container {
  margin: 0 auto;
  background-color: #f2f2f2;
}


.top-button {
  color: #000;
  background-color: #fff;
  text-align: center;
  display: inline-block;
  font-size: .9rem;
  letter-spacing: 1.5px;
  font-weight: bold;
  position: relative;
  // padding: 2.3% 1.5% 1.3% 1.5%;
  padding: 2rem 0.75rem 1rem 1rem;
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  border: 1.5px solid #000;
  transition:color .3s, background-color .3s, border .3s ;
  &:after {
    content: '';
    display:inline-block;
    width: .4rem;
    height: .4rem;
    border: 1px solid;
    border-color:  transparent transparent #000 #000;
    position: absolute;
    top: .7rem;
    right: 41%;
    transform: translateX(-50%);
    transform: rotate(135deg);
  }
}

body{
  position: relative;
}
