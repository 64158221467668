.main-image {
  background: url('images/home_mainimage.jpg') no-repeat center center;
  background-size:cover;
  height: 767px;
  position: relative;
  &-logo {
    width: 188px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(0,-50%);
    animation: fadein 3s;
    margin-left: -94px;
  }
  &-arrow-a {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    width: 60px;
    bottom: 20px;
    animation: Flash1 1s 3;
    animation-delay: 2s;
    img {
      width: 100%;
    }
  }
  @media only screen and (min-width: 1921px){
    height: 0;
    padding-top: 767 / 1920 * 100%;
    &-logo {
      width: 188 / 1920 * 100%;
      margin-left: -94 / 1920 * 100%;
    }
    &-arrow {
      width: 60 / 1920 * 100%;
      bottom: 30 / 1920 * 100%;
    }
  }
}



.contents {
  background-image: url(images/black.png);
  background-position: 50%;
  background-repeat: repeat-y;
  max-width: 1365px;
  margin: 0 auto;
  ol, ul {
    list-style: none;
    padding: 0;
  }

  &-about {
    text-align:  center;
    padding-top: 255px;
    margin-bottom: 345px;

    &-textarea {
      padding-bottom: 75px;
      display: flex;
      justify-content: center;
    }

    &-text1 {
      font-size: 29px;
      letter-spacing: 4px;
      font-weight: bold;
      text-align: right;
      margin-top: 0;
      margin-bottom: 0;
      display: inline-block;
      // margin-left: 17%;
      vertical-align: top;
      line-height: 50px;
      width: 50%;
    }

    &-text2 {
      font-size: 16px;
      letter-spacing: 2px;
      text-align: left;
      display: inline-block;
      margin-top: 0;
      margin-bottom: 0;
      padding-top: 23px;
      padding-left: 1.6%;
      vertical-align: top;
      line-height: 40px;
      width: 50%;

    }

    &-button {
      width: 166px;
      padding-top: 17px;
      padding-bottom: 17px;
      position: relative;
    }
  }
  &-news {
    text-align:  center;
    margin:auto;
    max-width: 1365px;
    margin-bottom: 345px;

    &-title {
      margin-bottom: 45px;

      &-1 {
        margin-left: 1.8%;
      }

      &-2 {
        margin-top: 48px;
        margin-left: 0;
      }

      span {
        font-size: 66px;
        letter-spacing: 4px;
        font-weight: bold;
        display: inline-block;
        vertical-align: top;
      }
    }

    &-wrap {
      margin-bottom: 120px;
      ul {
        padding-left: 0;
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        transition-delay: 1s;
        //animation: fadein2 3s;

      }

      li {
        background-color: #fff;
        vertical-align: top;
        position: relative;
        // width: 25%;
        width: 23.2%;
        // min-height: 370px;
      }
      a {
        display: block;
        height: 96%;
      }
    }

    &-imagewrapper {
      margin: 0 auto;
      overflow: hidden;
      width: 91.5%;
      margin-top: 4.5%;
      margin-bottom: 5.3%;

    }

    &-image {
      display: block;
      overflow: hidden;
      padding-top: 69%;
      // background: url(https://localhost.ssl/wp-content/uploads/2019/03/works_detail1.jpg) center center no-repeat;
      // background-size: 100% auto;
      transition: 0.5s;
    }

    &-defaultimagewrapper {
      background-color: #f2f2f2;
      margin: 0 auto;
      overflow: hidden;

    }

    &-defaultimage {
      padding-top: 21.4%;
      padding-bottom: 21.4%;
      width: 40.9%;
      transition: 0.5s;
    }

    &-link {
      text-align: left;
      font-size: 16px;
      letter-spacing: 2px;
      width: 91.1%;
      color: #000;
      text-decoration: none;
      font-weight: bold;
      display: block;
      margin: 0 auto 20%;
      line-height: 26px;
    }

    &-date {
      color: #999999;
      font-size: 14px;
      letter-spacing: 2px;
      position: absolute;
      // bottom: 14px;
      // left: 14px;
      left: 4.4%;
      bottom: 3.7%;
      font-weight: bold;
    }

    &-boxbutton {
      position: absolute;
      bottom: 3.6%;
      right: 4.2%;
      padding-top: 4.5%;
      padding-bottom: 4.5%;
      width: 51%;
    }

    &-button {
      width: 166px;
      padding-top: 17px;
      padding-bottom: 17px;
      position: relative;
    }

  }




  &-works {
    text-align:  center;
    max-width: 87.9%;
    margin:auto;
    padding-bottom: 150px;

    &-title {
      margin-bottom: 45px;

      &-1 {
        margin-left: 1%;
      }

      &-2 {
        margin-top: 49px;
        margin-left: -0.5%;
      }

      span {
        font-size: 66px;
        letter-spacing: 4px;
        font-weight: bold;
        display: inline-block;
        vertical-align: top;
      }

    }

    &-list {
      margin-bottom: 100px;

      &-content {
        a {
          display: block;
        }
      }
      &-link {
        display: flex;
        justify-content: space-between;
      }

      &-imagewrapper {
          overflow: hidden;
      }

      &-image {
        display: block;
        overflow: hidden;
        padding-top: 55.1%;
        transition: 0.5s;

      }

      &-defaultimagewrapper {
        margin: 0 auto;
        overflow: hidden;
        text-align: center;
        background-color: #fff;

      }

      &-defaultimage {
        padding-top: 18.6%;
        padding-bottom: 18.6%;
        width: 27.8%;
        transition: 0.5s;
      }



      ul {
        display: flex;
        flex-wrap: wrap;
      }

      li {
        width: 50%;
        text-align: left;
        position: relative;


        &:nth-child(even) .contents-works-list-imagewrapper {
          margin-left: 3.2%;
        }

        &:nth-child(even) .contents-works-list-title {
          padding-left: 3.2%;
        }

        // &:nth-child(odd) .contents-works-list-image {
        //   margin-right: 2.6%;
        // }

        &:nth-child(odd) .contents-works-list-imagewrapper {
          margin-right: 3.2%;
        }

        &:nth-child(odd) .contents-works-list-button {
          margin-right: 3.2%;
        }

        // &:nth-child(even) .contents-works-list-button {
        //   // margin-right: 2.6%;
        // }
      }


      &-content:nth-child(even) {
        margin-bottom: 6.2%;
      }

      &-content:nth-child(odd) {
        margin-top: 60px;
        // margin-bottom: 14px;
      }

      &-title {
        color: #000 ;
        font-size: 16px;
        letter-spacing: 2px;
        font-weight: bold;
        margin-top: 2.1%;
        width: 63%;
        line-height: 23px;

      }

      &-button {
        width: 166 / 600 * 100%;
        margin-top: 2.3%;
        padding-top: 2.5%;
        padding-bottom: 2.5%;
        height: 10%;
        position: relative;
      }
    }

    &-button {
      width: 166px;
      padding-top: 17px;
      padding-bottom: 17px;
      position: relative;
    }
  }



  &-button {
    color: #000;
    background-color: #fff;
    text-align: center;
    display: inline-block;
    font-size: 12px;
    letter-spacing: 2px;
    font-weight: bold;
    border: 1.5px solid #000;
    transition:all .3s;

    &:after {
      content: '';
      display:inline-block;
      width: 6px;
      height: 6px;
      border: 1px solid;
      border-color:  transparent transparent #000 #000;
      position: absolute;
      top: 41%;
      right: 7%;
      transform: translateY(-50%);
      transform: rotate(225deg);
    }
  }

}



@media only screen and (max-width: 960px) {
  .main-image {
    background: url(images/sp/home_mainimage.jpg) no-repeat top left;
    background-size:100%;
    height: 1120 / 20 + rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &-logo {
      width: 312 / 20 + rem;
      transform: translate(0,-50%);
      animation: fadein 3s;
      margin-left: -7.3rem;
    }

    &-arrow {
      display: none;
    }
  }


  .contents {
    background-image: url(images/black.png);
    background-position: 50%;
    background-repeat: repeat-y;
    margin: 0 auto;
    &-button {
      color: #000;
      background-color: #fff;
      text-align: center;
      display: inline-block;
      font-size: 0.9rem;
      letter-spacing: 0.1rem;
      font-weight: bold;
      border: 0.1rem solid #000;
      transition:all .3s;
      &:after {
        content: '';
        display:inline-block;
        width: .5rem;
        height: .5rem;
        border: 0.1rem solid;
        border-color:  transparent transparent #000 #000;
        position: absolute;
        top: 38%;
        right: 7%;
        transform: rotate(225deg);
      }
    }
    &-about {
      text-align:  center;
      padding-top: 336 / 20 + rem;
      margin-bottom: 400 / 20 + rem;

      &-textarea {
        padding-bottom: 80 / 20 + rem;
        display: flex;
        justify-content: center;
      }

      &-text1 {
        font-size: 36 / 20 + rem;
        letter-spacing: .2rem;
        font-weight: bold;
        text-align: right;
        margin-top: 0;
        margin-bottom: 0;
        display: inline-block;
        padding-right: .5rem;
        vertical-align: top;
        line-height: 63 / 20 + rem;
        width: 50%;
      }

      &-text2 {
        font-size: 19 / 20 + rem;
        letter-spacing: .1rem;
        text-align: left;
        display: inline-block;
        margin-top: 0;
        margin-bottom: 0;
        padding-top: 23 / 20 + rem;
        padding-left: 1.8rem;
        vertical-align: top;
        line-height: 50.4/ 20 + rem;
        width: 50%;

      }

      &-button {
        width: 250 / 20 + rem;
        padding: 1rem 0;
        position: relative;
        font-size:0.9rem;
      }
    }
    &-news {
      text-align:  center;
      margin:auto;
      margin-bottom: 20rem;

      &-title {
        margin-bottom: 90 / 20 + rem;

        &-1 {
          margin-right: -1.2rem;
          margin-left:0;
        }
        &-2 {
          margin-top: 3.8rem;
          margin-left: 0;
          margin-right: -2.5rem;
        }

        span {
          font-size: 99 / 20 + rem;
          letter-spacing: 1rem;
          font-weight: bold;
          display: inline-block;
          vertical-align: top;
        }
      }

      &-wrap {
        margin-bottom: 90 / 20 + rem;
        ul {
          display: flex;
          transition-delay: 1s;
          flex-direction: column;
        }

        li {
          background-color: #fff;
          vertical-align: top;
          position: relative;
          width: 477 / 20 + rem;
          margin: 0 auto;
          &:not(:nth-child(4)) {
            margin: 0 auto 3rem;
          }
        }
        a {
          display: block;
          height: 96%;
        }
      }

      &-imagewrapper {
        margin: 0 auto;
        overflow: hidden;
        width: 435 /  20 + rem;
        margin: 1rem auto 1.5rem;
      }

      &-image {
        display: block;
        overflow: hidden;
        height: 300 / 20 + rem;
        transition: 0.5s;
        padding-top: 0;
      }

      &-link {
        text-align: left;
        font-size: 1.2rem;
        letter-spacing: .1rem;
        width: 435 /  20 + rem;
        color: #000;
        text-decoration: none;
        font-weight: bold;
        display: block;
        margin: 0 auto 20%;
        line-height: 39 / 20 + rem;
      }

      &-date {
        color: #999999;
        font-size: .9rem;
        letter-spacing: .1rem;
        position: absolute;
        // bottom: 14px;
        // left: 14px;
        left: 1rem;
        bottom: 17 / 20 + rem;
        font-weight: bold;
      }

      &-boxbutton {
        position: absolute;
        right: 1rem;
        bottom: 17 / 20 + rem;
        padding: 1rem 0;
        width: 250 / 20 + rem;
      }

      &-button {
        width: 250 / 20 + rem;
        padding: 1rem 0;
        position: relative;
      }
    }




    &-works {
      text-align:  center;
      margin:auto;
      padding-bottom: 10rem;
      width: 100%;
      max-width: none;
      &-title {
        margin-bottom: 4.5rem;

        &-1 {
          margin-right: -1.2rem;
          margin-left:0;
        }
        &-2 {
          margin-top: 3.8rem;
          margin-left: 0;
          margin-right: -2.5rem;
        }
        span {
          font-size: 99 / 20 + rem;
          letter-spacing: 1rem;
          font-weight: bold;
          display: inline-block;
          vertical-align: top;
        }

      }

      &-list {
        margin-bottom: 4.5rem;

        &-link {
          display: flex;
          padding: 0 1rem ;
        }

        &-imagewrapper {
            overflow: hidden;
            margin-left: 0!important;
        }

        &-image {
          display: block;
          overflow: hidden;
          padding-top: 55.1%;
          transition: 0.5s;
        }
        li {
          width: 100%;
          text-align: left;
          position: relative;
          margin-bottom: 4.5rem;

          &:nth-child(odd) .contents-works-list-imagewrapper {
            margin-right: 0;
          }

          &:nth-child(odd) .contents-works-list-button {
            margin-right: 0;
          }
        }


        &-content:nth-child(even) {
          margin-bottom: 0;
        }

        &-content:nth-child(odd) {
          margin-top: 60px;
          // margin-bottom: 14px;
        }

        &-title {
          color: #000 ;
          font-size: 1.2rem;
          letter-spacing: .1rem;
          font-weight: bold;
          margin-top: 2.1%;
          width: 310 / 20 + rem;
          line-height: 39 / 20 + rem;

        }
        &-button {
          width: 250 / 20 + rem;
          margin-top: 1rem;
          padding:1rem 0;
          height: 63 / 20 + rem;
          position: relative;
        }
      }

      &-button {
        width: 250 / 20 + rem;
        padding: 1rem;
        position: relative;
      }
    }
  }
}
