
.aboutcontents {
  // max-width: 1365px;
  margin: 0 auto;


  &-about {
    text-align:  center;
    overflow-x: hidden;

    &-title {
      margin-bottom: 45px;
      padding-top: 80px;

      &-1 {
        margin-left: 0.5%;
      }

      &-2 {
        margin-top: 49px;
        margin-left: -0.5%;
      }

      span {
        font-size: 66px;
            letter-spacing: 10px;
        font-weight: bold;
        display: inline-block;
        vertical-align: top;
      }
    }


    &-image {
      background: url(images/about.jpg) no-repeat center center;
      background-size:cover;
      height: 0;
      height: 580px;
      position: relative;
      @media only screen and (max-width: 1320px){
        background-position-x: -300px;
      }
      &-text {
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 540px;
        position: absolute;
        display: flex;
        justify-content: center;
      }
      &-text1 {
        margin-left:30px;

        font-size: 29px;
        letter-spacing: 4px;
        font-weight: bold;
        color: #fff;
        text-align: right;
        line-height: 50px;
        padding-bottom: 25px;
      }

      &-text2 {
        margin-left:10px;
        font-size: 29px;
        letter-spacing: 5px;
        font-weight: bold;
        color: #fff;
        text-align: left;
        line-height: 45px;
        padding-top: 25px;
      }
      @media only screen and (min-width: 1921px){
        padding-top: 580 / 1920 * 100%;
        &-text {
          top: 47%;
          left: 53%;
          transform: translate(-50%,-50%);
          width: 540 / 1920 * 100%;
          position: absolute;
          display: flex;
          justify-content: center;
        }
        &-text1 {
          font-size: 29px;
          letter-spacing: 3px;
          font-weight: bold;
          color: #fff;
          text-align: right;
          line-height: 50px;
          padding-bottom: 25px;
        }

        &-text2 {
          font-size: 29px;
          letter-spacing: 3px;
          font-weight: bold;
          color: #fff;
          text-align: left;
          line-height: 45px;
          padding-top: 25px;
        }
      }
    }


    &-content {
      margin: 0 auto;
      max-width: 1365px;
      padding-top: 77px;

      &-title {
        font-size: 26px;
        letter-spacing: 5px;
        font-weight: bold;
      }

      &-1 {
        margin-top: 50px;
        margin-bottom: 60px;
        ul {
          padding: 0;
        }
        li {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 16px;
          line-height: 34px;
          letter-spacing: 3px;
          margin-bottom: 27px;
          small {
            line-height: 26px;
          }
        }

        img {
          width: 6px;
          margin-right:40px;
          margin-left: 40px;
        }

        &-left {
          width: 42%;
          text-align: right;
        }

        &-right {
          width: 52%;
          text-align: left;
        }

        li:last-child {
          align-items: flex-start;

          img {
            margin-top: 11px;
          }
        }
      }

      &-2 {
        margin: 0 auto;
        max-width: 73.2%;
        text-align: left;
        margin-bottom: 115px;

        &-title {
          text-align: center;
          font-size: 17px;
          letter-spacing: 3px;
          font-weight: bold;
          // height: 18px;
          width: 206px;
          background-color: #fff;
          border: 2px solid;
          padding-top: 11px;
          padding-bottom: 11px;
          margin-bottom: 28px;
        }

        &-text {
          font-size: 13px;
          letter-spacing: 2.5px;
          line-height: 42px;
          display: block;
        }

        &-text2 {
          font-size: 12px;
          letter-spacing: 2.5px;
          margin-bottom: 60px;
        }

        &-map {
          border: 14px solid #fff;
          margin-top: 10px;
          margin-bottom: 20px;

          height: 0;
          overflow: hidden;
          padding-bottom: 38.27%;
          position: relative;
        }

        iframe {
          position: absolute;
          left: 0;
          top: 0;
          height: 100% !important;
          width: 100% !important;
        }

      }

      &-3 {
        margin: 0 auto;
        max-width: 73.2%;

        &-text1 {
          font-size: 20px;
          letter-spacing: 3px;
          font-weight: bold;
          margin-top: 36px;
          margin-bottom: 21px;
        }

        &-text2 {
          font-size: 16px;
          letter-spacing: 2.5px;
          font-weight: bold;
          margin-bottom: 65px;
        }

        p {
          text-align: left;
          font-size: 16px;
          letter-spacing: 2px;
          line-height: 36px;
          letter-spacing: 1.7px;
          margin-bottom: 117px;
        }
      }


      &-4 {
        text-align: center;
        margin-top: 42px;
        padding-bottom: 58px;

        &-title {
          font-size: 20px;
          letter-spacing: 3px;
          font-weight: bold;
          margin-top: 27px;
          margin-bottom: 21px;
        }

        &-year {
          font-size: 16px;
          letter-spacing: 3px;
        }

        &-text {
          font-size: 16px;
          letter-spacing: 2px;
          line-height: 36px;
        }

        &-awardwrap {
          margin-bottom: 65px;
        }
      }

    }

  }
}

@media only screen and (max-width: 960px){


.aboutcontents {
  // max-width: 1365px;
  margin: 0 auto;


  &-about {
    text-align:  center;
    overflow-x: hidden;

    &-title {
        padding: 90 / 20 + rem 0;
        margin-bottom: 0;

      &-1 {
        margin-left: 2.5%;
      }

      &-2 {
        margin-top:3.45rem;
        margin-left: -0.3em;
      }

      span {
        font-size: 99 / 20 + rem;
        letter-spacing: 170 / 1000 * 1em;
        font-weight: bold;
        display: inline-block;
        vertical-align: top;
      }
    }


    &-image {
      background: url(images/sp/about.jpg) no-repeat center center;
      background-size:cover;
      height: 0;
      height: 1120 / 20 + rem;
      position: relative;
      @media only screen and (max-width: 1320px){
        background-position-x: 0px;
      }
      &-text {
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 610 / 20 + rem;
        position: absolute;
        display: flex;
        justify-content: center;
      }
      &-text1 {
        margin-left:0;

        font-size: 34.8 / 20 + rem;
        letter-spacing: 250 / 1000 * 1em;
        white-space: nowrap;
        font-weight: bold;
        color: #fff;
        text-align: right;
        line-height: 63.6 / 20 + rem;
        padding-bottom: 30 / 20 + rem;
      }

      &-text2 {
        margin-left:36 / 20 + rem;
        font-size: 34.8 / 20 + rem;
        letter-spacing: 170 / 1000 * 1em;
        font-weight: bold;
        color: #fff;
        text-align: left;
        line-height: 63.6 / 20 + rem;
        padding-top: 30 / 20 + rem;
      }
    }


    &-content {
      margin: 0 auto ;
      max-width: none;
      padding-top: 120 / 20 + rem;

      &-title {
        font-size: 39 / 20 + rem;
        letter-spacing: 250 / 1000 * 1em;
        font-weight: bold;
        white-space: nowrap;
      }

      &-1 {
        margin: 4rem 1rem 10rem 3rem ;


        li {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 24 / 20 + rem;
          line-height: 39 / 20 + rem;
          letter-spacing: 250 / 1000 * 1em;
          margin-bottom: 60 / 20 + rem;

          small{
            font-size: 21 / 20 + rem;
            letter-spacing: 170 / 1000 * 1em;
            line-height: 39 / 20 + rem;
          }
        }

        img {
          width: 7 / 20 + rem;
          height: 15 / 20 + rem;
          margin-right: 20 / 20 + rem;
          margin-left: 20 / 20 + rem;
          @media all and (-ms-high-contrast: none) {
            *::-ms-backdrop, .selector {
              margin-top: 0.3rem;
            }
          }
        }

        &-left {
          width: 130 / 20 + rem;
          text-align: right;
        }

        &-right {
          width: 520 / 20 + rem;
          text-align: left;
          line-height: 39 / 20 + rem;
        }

        li:last-child {
          align-items:start;

          img {
            margin-top: 0.65rem;
          }
        }
      }

      &-2 {
        margin: 0 auto;
        max-width: 670 / 20 + rem;
        text-align: left;
        margin-bottom: 160 / 20 + rem;

        &-title {
          text-align: center;
          font-size: 24 / 20 + rem;
          letter-spacing: 250 / 1000 * 1em;
          font-weight: bold;
          // height: 18px;
          width: 309 / 20 + rem;
          background-color: #fff;
          border: 2 / 20 + rem solid;
          padding-top: 18 / 20 + rem;
          padding-bottom: 18 / 20 + rem;
          margin-bottom: 45 / 20 + rem;
        }

        &-text {
          font-size: 20 / 20 + rem;
          letter-spacing: 250 / 1000 * 1em;
          line-height: 38 / 20 + rem;
          margin: 30 / 20 + rem 0;
          display: block;
        }

        &-text2 {
          font-size: 16 / 20 + rem;
          line-height: 24 / 20 + rem;
          letter-spacing: 250 / 1000 * 1em;
          margin-bottom: 90 / 20 + rem;
        }

        &-map {
          border: 20 / 20 + rem solid #fff;
          margin-top: 45 / 20 + rem;
          margin-bottom: 23 / 20 + rem;

          height: 0;
          overflow: hidden;
          padding-bottom: 558 / 20 + rem;
          position: relative;
        }

        iframe {
          position: absolute;
          left: 0;
          top: 0;
          height: 100% !important;
          width: 100% !important;
        }

      }

      &-3 {
        margin: 0 auto;
        max-width: 670 / 20 + rem;

        &-text1 {
          font-size: 30 / 20 + rem;
          letter-spacing: 250 / 1000 * 1em;
          font-weight: bold;
          margin-top: 60 / 20 + rem;
          margin-bottom: 24 / 20 + rem;
        }

        &-text2 {
          font-size: 24 / 20 + rem;
          letter-spacing: 250 / 1000 * 1em;
          font-weight: bold;
          margin-bottom: 95 / 20 + rem;
        }

        p {
          text-align: left;
          font-size: 24 / 20 + rem;
          letter-spacing: 250 / 1000 * 1em;
          line-height: 63 / 20 + rem;
          margin-bottom: 170 / 20 + rem;
        }
      }


      &-4 {
        text-align: left;
        padding-bottom: 80 / 20 + rem;
        margin: 48 / 20 + rem auto 0;
        max-width: 670 / 20 + rem;
        &-title {
          font-size: 30 / 20 + rem;
          letter-spacing: 250 / 1000 * 1em;
          font-weight: bold;
          margin-top: 34 / 20 + rem;
          margin-bottom: 34 / 20 + rem;
        }

        &-year {
          font-size: 24 / 20 + rem;
          letter-spacing: 250 / 1000 * 1em;
        }

        &-text {
          font-size: 24 / 20 + rem;
          letter-spacing: 250 / 1000 * 1em;
          line-height: 63 / 20 + rem;
          padding-left: 1em;
          text-indent: -1em;

          .bullet{
            display: inline-block;
            width: 1em;
            text-align: center;
            text-indent: 0;
          }
        }

        &-awardwrap {
          margin-bottom: 100 / 20 + rem;
        }
      }

    }

  }


}

}
