
.worksdetailcontents {
  max-width: 1365px;
  margin: 0 auto;


  &-works {
    max-width: 73.2%;
    margin: 0 auto;
    padding-top:78px;

    h1 {
      font-size: 26px;
      letter-spacing: 3px;
      font-weight: bold;
      // margin-top: 76px;
      margin-bottom: 10px;
      line-height: 30px;
      margin-top: 0;
    }

    &-share {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

    }
    &-rolewrap{
      padding: 0;
      margin: 0;
    }

    &-role {
      display:inline-block;
      font-size: 14px;
      letter-spacing: 1.5px;
      position: relative;
      margin-right: 28px;
      line-height: 20px;
      a {
        color: #000;
      }
    }

    &-role:first-child {
      padding-left: 0;
    }

    &-role:not(:last-child):after {
      content: "";
      border-right: 1px solid #000;
      position: absolute;
      right: -14px;
      height: 15px;
      bottom: 0;
      line-height: 15px;
    }
    &-sharebuttonwrap {
      display: flex;
      width: 30%;
      align-items: center;
      justify-content: flex-end;
    }

    &-arrow {
      font-weight: bold;
      font-size: 12px;
      letter-spacing: 2px;
      position: relative;
      // margin-left: 60%;
      margin-right: 17.5%;
      font-weight: bold;

      &:after {
        content: '';
        display:inline-block;
        width: 6px;
        height: 6px;
        border: 1px solid;
        border-color:  transparent transparent #000 #000;
        position: absolute;
        top: 2px;;
        right: -29%;
        transform: translateY(-50%);
        transform: rotate(225deg);
      }
    }

    &-twitter {
      margin-right: 11%;

      width: 25px;
      height: 22px;

      img {
        // width: 100%;
        width: 25px;
        display: block;
      }
    }

    &-facebook {
      width: 14px;
      height: 26px;

      img {
        // width: 100%;
        width: 14px;
        display: block;
      }
    }

    &-defaultimagewrapper {
      margin-bottom: 44px;
      margin-top: 11px;
    }

    &-defaultimage {
      width: 28.1%;

      display: inline-block;
    }

    &-mainimage {
      width: 100%;
      margin-bottom: 44px;
    }

    &-content {
      span {
         line-height: calc( 1em + 16px);
      }

      p {
        font-size: 16px;
        line-height: 40px;
        letter-spacing: 2.8px;
        // margin-bottom: 100px;
      }

      li {
        font-size: 16px;
        line-height: 40px;
        letter-spacing: 2.8px;
      }

      ol {
        font-size: 16px;
        line-height: 40px;
        letter-spacing: 2.8px;
      }

      a {
        font-size: 16px;
        line-height: 40px;
        letter-spacing: 2.8px;
        // text-decoration: underline;
      }


      &-1 {
        margin-bottom: 55px;

      }

      &-2 {
        margin-bottom: 55px;
      }

      &-3 {
        margin-bottom: 35px;
      }

      &-4 {
        background-color: #fff;
        padding: 10px;
        margin-bottom: 20px;

        p {
          font-size: 14px;
        }
      }

      &-5 {
        margin-bottom: 20px;
      }

      &-6 {
        margin-bottom: 20px;
      }

      &-introductory {
        margin-bottom: 35px;
      }

      &-credit {
        background-color: #fff;
        padding: 10px;
        margin-bottom: 20px;
        font-family: "YuKyokasho","游教科書体","游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;
        p {
          font-size: 14px;
        }

        li {
          font-size: 14px;
        }

        ol {
          font-size: 14px;
        }
      }

      &-main {
        margin-bottom: 60px;
      }

    }

    &-contentimage1 {
      text-align: center;
      margin-bottom: 20px;

      img {
        width: 33.6%;
      }
    }

    &-contentimage2 {
      text-align: center;
      margin-bottom: 60px;

      img {
        width: 51.2%;
      }
    }


    &-buttons {
      // display: flex;
      // justify-content: space-between;
      padding-bottom: 130px;
      position: relative;
      max-width: 100%;
      // margin:0 auto;
    }

    &-buttonleft {
      // margin-right: 24.5%;
      position: absolute;
      display: block;
      // float: left;
      top: 0;
      left: 0;
      width: 166px;
    }

    &-buttoncenter {
      // margin-right: 24.5%;
      position: absolute;
      top: 0;
      // bottom: 49px;
      left: 41%;
      // margin:0 auto
      // display: block;
      // left: 24.3%;
    }

    &-buttonright {
      position: absolute;
      // left: 800px;
      // display: block;
      // float: right;
      right: 0;
      top: 0;
    }
  }

  &-button {
    color: #000;
    background-color: #fff;
    text-align: center;
    display: inline-block;
    font-size: 12px;
    letter-spacing: 2px;
    font-weight: bold;
    border: 1.5px solid #000;

    width: 166px;
    padding-top: 17px;
    padding-bottom: 17px;
    position: relative;
    transition:all .3s;

    &:after {
      content: '';
      display:inline-block;
      width: 6px;
      height: 6px;
      border: 1px solid;
      border-color:  transparent transparent #000 #000;
      position: absolute;
      top: 41%;
      right: 7%;
      transform: translateY(-50%);
      transform: rotate(225deg);
    }
  }

  &-arrowleftbutton {
    color: #000;
    background-color: #fff;
    text-align: center;
    display: inline-block;
    font-size: 12px;
    letter-spacing: 2px;
    font-weight: bold;
    border: 1.5px solid #000;

    width: 166px;
    padding-top: 17px;
    padding-bottom: 17px;
    position: relative;
    transition:all .3s;

    &:after {
      content: '';
      display:inline-block;
      width: 6px;
      height: 6px;
      border: 1px solid;
      border-color:  transparent transparent #000 #000;
      position: absolute;
      top: 41%;
      right: 85%;
      transform: translateY(-50%);
      transform: rotate(45deg);
    }
  }
}

@media only screen and (max-width: 960px){
  .worksdetailcontents {
    iframe {
      width: 100%;
    }
    &-works {
      width: 670 / 20 + rem;
      max-width:none;
      margin: 0 auto;
      padding-top: 0;
      h1 {
        font-size: 39 / 20 + rem;
        line-height: 64 / 20 + rem;
        letter-spacing: 3 / 20 + rem;
        font-weight: bold;
        padding-top: 4rem;
        margin-bottom: 10 / 20 + rem;
      }
      &-share {
        display: block;
        margin-bottom: 2.2rem;
      }
      &-date {
        font-size: 1rem;
        letter-spacing: .1rem;
      }
      &-rolewrap {
        margin-bottom: 2rem;

      }
      &-role {
        font-size: 1rem;
        letter-spacing: .2rem;
        position: relative;
        padding-right: 15/ 20 + rem;
        margin-right: 15/ 20 + rem;
        line-height: 1.5rem;
        &:not(:last-child):after {
          border-right: 1px solid #000;
          position: absolute;
          right: 0;
          height: 1rem;
          top: 0.2rem;
          line-height: 1.5rem;
        }
      }

      &-sharebuttonwrap {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: flex-end;
      }

      &-arrow {
        font-weight: bold;
        font-size: .9rem;
        letter-spacing: .1rem;
        position: relative;
        margin-right: 73 / 20 + rem;
        font-weight: bold;
        &:after {
          content: '';
          display:inline-block;
          width: .4rem;
          height: .4rem;
          border: .1rem solid;
          border-color:  transparent transparent #000 #000;
          position: absolute;
          top: .1rem;
          right: -25 / 20 + rem;
          transform: translateY(-50%);
          transform: rotate(225deg);
        }
      }

      &-twitter {
        margin-right: 45 / 20 + rem;
        width: 2rem;
        height: auto;
        img {
          width: 2rem;
          display: block;
        }
      }

      &-facebook {
        width: 1rem;
        height: auto;

        img {
          width: 1rem;
          display: block;
        }
      }

      &-defaultimagewrapper {
        margin-bottom: 2.2rem;
        margin-top: .5rem;
      }

      &-defaultimage {
        width: 40.9%;
        display: inline-block;
      }

      &-mainimage {
        width: 100%;
        margin-top: 30/ 20 + rem;
      }

      &-content {
        span {
           line-height: calc( 1em + 1.2rem);
        }
        img {

        }
        p {
          font-size: 1.2rem;
          line-height: 63 / 20 + rem;
          letter-spacing: 2.5 /20 + rem;
          // margin-bottom: 100px;
        }

        li,ol,li,a {
          font-size: 1.3rem;
          line-height: 40 / 20 + rem;
          letter-spacing: 2 /20 + rem;
        }
        a {
          text-decoration: underline;
        }
        &-introductory {
          margin-bottom: 65 / 20 + rem;
        }

        &-credit {
          background-color: #fff;
          padding: 1rem;
          margin-bottom: 4rem;
          font-family: "YuKyokasho","游教科書体","游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;
          p {
            font-size: 1rem;
          }

          li {
            font-size: 14px;
          }

          ol {
            font-size: 14px;
          }
        }

        &-main {
          margin-bottom: 60px;
        }

      }

      &-contentimage1 {
        text-align: center;
        margin-bottom: 20px;

        img {
          width: 33.6%;
        }
      }

      &-contentimage2 {
        text-align: center;
        margin-bottom: 60px;

        img {
          width: 51.2%;
        }
      }
      &-buttons {
        padding-bottom: 10rem;
        margin-left:-1rem ;
        margin-right:-1rem ;
        max-width: none;
      }
      &-buttoncenter {
        left: 250 / 20 + rem;
      }

      &-buttonright {
        position: absolute;
        right: 0;
        top: 0;
      }

    }

    &-button,&-arrowleftbutton,&-buttoncenter{
      color: #000;
      background-color: #fff;
      text-align: center;
      display: inline-block;
      font-size: .9rem;
      letter-spacing: .1rem;
      font-weight: bold;
      border: 0.1rem solid #000;
      width: 210 / 20 + rem;
      padding: 1rem;
      &:after {
        content: '';
        display:inline-block;
        width: .4rem;
        height: .4rem;
        border: .1rem solid;
        border-color:  transparent transparent #000 #000;
        position: absolute;
        top: 41%;
        right: 7%;
        transform: translateY(-50%);
        transform: rotate(225deg);
      }
    }
    .worksdetailcontents-works-buttoncenter,&-arrowleftbutton {
      &:after {
        transform: rotate(45deg)!important;
        left: 7%!important;
        right:auto!important;

      }
    }
  }
}
