.pcHover {
	.hoverImg {
		position: relative;
	 	&:hover{
    	opacity: 1!important;
		}
		img {
			position: absolute;
		  top:0;
		  left: 0;
		}
		img.on {
		  opacity: 0;
		}
		&:hover {
		  opacity: 1;
	  	img.on {
		  	opacity: 1;
    	}
			img.off {
		  	opacity: 0;
			}
	  }
  }
  a.hoverOpacity {
  	img {
  		transition: .3s;
  	}
  	&:hover {
  		img {
  			opacity: 0.5;
  		}
  	}
  }
  .header-inner-logo a:hover svg{
    fill: #999;
  }
  .workscontents  {
    .workscontents-works-content:hover{
      cursor: pointer;
      background-position: -100% 0;
      .workscontents-works-content-defaultimage {
        transform: scale(1.2,1.2);
      }
      .workscontents-works-content-image {
        -moz-transform: scale(1.1);
        -webkit-transform: scale(1.1);
        -ms-transform: scale(1.1);
        transform: scale(1.1);
      }
      .workscontents-works-content-under {
        // cursor: pointer;
        // background-position: -100% 0;
      }
      .workscontents-works-content-under-movietitle {
        color: #fff;
      }
      .workscontents-works-content-under-role {
        color: #fff;
      &:after {
        border-color: #fff;
      }

      }
      .workscontents-works-content-under-role:first-child{
         border-left:none;
         padding-left: 0;
      }
    }
  }
  .workscontents-morebutton,.newscontents-morebutton {
    &:hover{
      color: #fff;
      background-color: #000;
      border: 1.5px solid #000;

      &:after {
        border-color:  transparent transparent #fff #fff;
      }
    }
  }
  .worksdetailcontents-works-content{
    a:hover { text-decoration: underline; }
  }
  .top-button {
    &:hover{
      color: #fff;
      background-color: #000;
      border: 1.5px solid #000;

      &:after {
        border-color:  transparent transparent #fff #fff;
      }
    }
  }
  .worksdetailcontents-arrowleftbutton,.worksdetailcontents-button {
    &:hover{
      color: #fff;
      background-color: #000;
      border: 1.5px solid #000;

      &:after {
        border-color:  transparent transparent #fff #fff;
      }
    }
  }
  .workscontents-works-categories a{
    transition: opacity .3s;
    &:hover {
      opacity: 0.5;
    }
  }

  .recruitcontents-recruit{
   a:hover {
     transition: all 0.3s;
     border-color: #555DD5;
   }
  }

  .newscontents-news-wrap{
    li:hover {
      .newscontents-news-boxbutton {
        color: #fff;
        background-color: #000;
        border: 1.5px solid #000;

        &:after {
          border-color:  transparent transparent #fff #fff;
        }
      }
      .newscontents-news-image {
        -moz-transform: scale(1.2);
        -webkit-transform: scale(1.2);
        -ms-transform: scale(1.2);
        transform: scale(1.2);
      }
      .newscontents-news-defaultimage {
        transform: scale(1.2,1.2);
      }
    }
    &-button:hover{
      color: #fff;
      background-color: #000;
      border: 1.5px solid #000;
      &:after {
        border-color:  transparent transparent #fff #fff;
      }
    }
  }

  .newsdetailcontents-news-buttons{
    a:hover{
      color: #fff;
      background-color: #000;
      border: 1.5px solid #000;

      &:after {
        border-color:  transparent transparent #fff #fff;
      }
    }
  }
  .newsdetailcontents-news-text {
    a:hover { text-decoration: underline; }
  }
  .contents-about{
    &-button:hover {
      color: #fff;
      background-color: #000;
      border: 1.5px solid #000;

      &:after {
        border-color:  transparent transparent #fff #fff;
      }
    }
  }
  .contents-news-wrap{
    li:hover {
      .contents-news-boxbutton {
        color: #fff;
        background-color: #000;
        border: 1.5px solid #000;

        &:after {
          border-color:  transparent transparent #fff #fff;
        }
      }
      .contents-news-image {
        -moz-transform: scale(1.2);
        -webkit-transform: scale(1.2);
        -ms-transform: scale(1.2);
        transform: scale(1.2);
      }
      .contents-news-defaultimage {
        transform: scale(1.2,1.2);
      }
    }
  }
  .contents-news-button:hover {
    color: #fff;
    background-color: #000;
    border: 1.5px solid #000;

    &:after {
      border-color:  transparent transparent #fff #fff;
    }
  }
  .contents-works-list-content:hover {
    // img {
    //   transform: scale(1.2, 1.2);
    // }

    .contents-works-list-image {
      -moz-transform: scale(1.2);
      -webkit-transform: scale(1.2);
      -ms-transform: scale(1.2);
      transform: scale(1.2);
    }

    .contents-works-list-button {
      color: #fff;
      background-color: #000;
      border: 1.5px solid #000;

      &:after {
        border-color:  transparent transparent #fff #fff;
      }

    }

    .contents-works-list-defaultimage {
      transform: scale(1.2,1.2);
    }
  }
  .contents-works{
    &-button:hover {
      color: #fff;
      background-color: #000;
      border: 1.5px solid #000;

      &:after {
        border-color:  transparent transparent #fff #fff;
      }
    }
  }
  .header-inner-link {
      &:hover, &.active {
        cursor: pointer;
        &:after {
          width: 100%;
          left: 0;
        }
      }
  }
  .footer-inner-contact {
    a:hover {
        cursor: pointer;
        &:after {
          width: 100%;
          left: 0;
        }
    }
  }
}
