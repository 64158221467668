/*!
Theme Name: GLASSGOW
Theme URI:
Description:
Version:1.0.20190305
*/

@import url("fonts/webfonts/Montserrat.css");

// @import url("../wp-content/themes/glasgow/fonts/webfonts/Montserrat.css");
@import "__hover";

@import "__reset";
@import "__header";
@import "__footer";
@import "__index";
@import "__news";
@import "__news_detail";
@import "__works";
@import "__works_detail";
@import "__about";
@import "__recruit";
@import "__animation";
@import "__mixin";


@media only screen and (min-width: 961px) {
	.forsp{
		display:none !important;
	}

}


@media only screen and (max-width: 960px) {
	.forpc{
		display:none !important;
	}
	// @import "__sp";
	html {
		font-size: 2.66667vw;
	}
}
