.recruitcontents {
  max-width: 1365px;
  margin: 0 auto;
  &-recruit {
    text-align:  center;

    &-title {
      margin-bottom: 45px;
      padding-top: 80px;

      &-1 {
        margin-left: 0.5%;
      }

      &-2 {
        margin-top: 49px;
        margin-left: -0.5%;
      }

      span {
        font-size: 66px;
        letter-spacing: 10px;
        font-weight: bold;
        display: inline-block;
        vertical-align: top;
      }
    }

    &-content {
      span {
         line-height: calc( 1em + 24px);
      }
      margin: 0 auto;
      // max-width: 1000px;
      max-width: 73.2%;
      text-align: left;
      margin-top: 85px;
      padding-bottom: 130px;

      &-title {
        font-size: 26px;
        letter-spacing: 5px;
        font-weight: bold;
        padding-bottom: 20px;
      }

      &-date {
        font-size: 14px;
        letter-spacing: 2px;
        font-weight: bold;
        color: #999;
        padding-bottom: 58px;
      }

      p {
        font-size: 16px;
        letter-spacing: 2px;
        line-height: 38px;
      }

      a{
        border-bottom: 1px solid transparent;
      }
      ul {
        padding-inline-start: 20px;
      }
      li {
        font-size: 16px;
        letter-spacing: 2px;
        line-height: 38px;
      }

      h2 {
        font-size: 20px;
        letter-spacing: 2px;
        font-weight: bold;
      }
    }

  }
}

@media only screen and (max-width: 960px){
  .recruitcontents {
    max-width: none;
    &-recruit {
      &-title {
        padding: 90 / 20 + rem 0;
        margin-bottom: 0;
        &-1 {
          margin-right: -1.2rem;
          margin-left:0;
        }
        &-2 {
          margin-top: 3.8rem;
          margin-left: 0;
          margin-right: -2.5rem;
        }
        span {
          font-size: 99 / 20 + rem;
          letter-spacing: 1rem;
        }
      }

      &-content {
        span {
           line-height: calc( 1em + 1.2rem);
        }
        margin: 0 2rem;
        margin-top: 0;
        padding-bottom: 200 / 20 + rem;
        max-width: none;
        &-title {
          font-size: 39 / 20 + rem;
          letter-spacing: 0.15rem;
          padding-bottom: 1.5rem;
          line-height: 63 / 20 + rem;
        }
        &-date {
          font-size: 1.05rem;
          letter-spacing: .1rem;
          padding-bottom: 1rem;
        }
        p {
          font-size: 1.2rem;
          letter-spacing: .1rem;
          line-height: 63 / 20 + rem;
        }
        a{
          text-decoration: underline;
        }
        ul {
          padding-inline-start: 1.5rem;
        }
        li {
          font-size: 1.2rem;
          letter-spacing: 0.15rem;
          line-height: 63 / 20 + rem;
        }

        h2 {
          font-size: 1.5rem;
          letter-spacing: .1rem;
          margin-bottom: 1.5rem;
        }
      }

    }
  }

}
